.OnePage-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;
}

.OnePage-top-section {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: #222222;
  width: 100%;
  height: 468px;

  margin: 0 0 10px 0;
}

.OnePage-container-portada {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;

  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
}

.OnePage-portada-container {
  position: relative;
  margin: 0 0 10px 0;
}

.OnePage-portada-container:hover > .OnePage-play-button-container {
  visibility: visible;
}

.OnePage-play-button-container {
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.OnePage-play-button {
  color: #eeeeee;
  font-size: 70px;
}

.OnePage-container-background {
  z-index: 0;
  position: absolute;
  /* background-image: url(./../../../assets/background/NoValePortada.jpeg); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;

  filter: blur(6px);
}

.OnePage-portada {
  width: 350px;
}

.OnePage-by-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #222222;
  margin: 15px 0;
}

.OnePage-by-title {
  font-family: portadaFontFamily;
  font-size: 40px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0 0 5px 0;
}

.OnePage-by-banda {
  color: #555555;
  font-family: portadaFontFamily;
  font-size: 20px;

  font-style: italic;

  text-transform: capitalize;
}

.OnePage-by-banda > a {
  color: #222222;
}

.OnePage-button-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 75px;
}

.OnePage-button-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: min(450px, 100%);
}

.OnePage-button-text {
  color: #555555;
  margin: 25px 0 15px;
  font-family: portadaFontFamily;
}

.OnePage-redes-container-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.OnePage-redes-button {
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: transparent;
  color: #222222;
  text-transform: capitalize;
  height: 50px;
  width: 100%;
  font-size: 23px;
  border: 1px solid #444444;
  border-radius: 30px;
}

.OnePage-redes-button:hover {
  background-color: #222222;
  color: #eeeeee;
}

.OnePage-redes-button:hover > * {
  stroke: #eeeeee;
  fill: #eeeeee;
}

.OnePage-redes-button-icon {
  color: #222222;
  height: 30px;
  width: 30px;
  margin-right: 20px;
  stroke: #222222;
  fill: #222222;
}

/** START MEDIA**/
@media (max-width: 480px) {
  .OnePage-by-title {
    font-size: 25px;
  }

  .OnePage-by-banda {
    font-size: 14px;
  }

  .OnePage-button-list {
    grid-template-columns: 1fr;
    width: 80%;
  }
}
