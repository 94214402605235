#root {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.App-container {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: transparent;
}

.App-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.App-switch-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
