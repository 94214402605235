@font-face {
  font-family: bandaFontFamily;
  src: url("./../fonts/Slave-only-dreams-to-be-king.woff2");
}

@font-face {
  font-family: portadaFontFamily;
  src: url("./../fonts/Cinzel-Regular.otf");
}

@font-face {
  font-family: subTituloFontFamily;
  src: url("./../fonts/DarkPoestry.ttf");
}
