html,body {
  --gradientColor1: #9a11e4;
  --gradientColor2:rgba(230, 175, 17, 0);
  --gradientBackground: #280a38;
  height: 100%;
}
body {
  background: var(--gradientColor2);
  background: radial-gradient(ellipse at center,  var(--gradientBackground)  0%, #000000 70%);
  background-size: 100%;
}
p {
  margin: 0;
  padding: 0;
}

.Counter-logo{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: invert(1);
}

#clock {
  
  font-family: 'Share Tech Mono', monospace;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #daf6ff;
  text-shadow: 0 0 20px var(--gradientColor1),  0 0 20px var(--gradientColor2);
  width: 100%;
  overflow-wrap: break-word;

  .time {
      letter-spacing: 0.05em;
      font-size: 8vw;
      padding: 5px 0;
  }
  .date {
      letter-spacing: 0.1em;
      font-size: 24px;
  }
  .text {
      letter-spacing: 0.1em;
      font-size: 12px;
      padding: 20px 0 0;
  }
}

.Counter-icon-container {
  margin: 10px;
  cursor: pointer;
}

/* .Footer-icon-container:hover {
  margin: 9px 9px;
} */

.Counter-icon-container{
  width: 100%;

}

.Counter-icon {
  width: 30px;
  height: 30px;
  stroke: white;
  fill: white;
}

.facebook-icon:hover > * {
  stroke: #4267b2;
  fill: #4267b2;
}

.twitter-icon:hover > * {
  stroke: #1da1f2;
  fill: #1da1f2;
}

.instagram-icon:hover > * {
  stroke: #c32aa3;
  fill: #c32aa3;
}

.spotify-icon:hover > * {
  stroke: #1ed760;
  fill: #1ed760;
}

.souncloud-icon:hover > * {
  stroke: #ff5500;
  fill: #ff5500;
}

.youtube-icon:hover > * {
  stroke: #ff0000;
  fill: #ff0000;
}