@keyframes maskAnimation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

@keyframes wrapperAnimation {
  0% {
    opacity: 0;
    margin-bottom: 300px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

.Modal-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: maskAnimation;
  animation-duration: 0.3s;
}

.Modal-wrapper {
  margin-bottom: 0;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  padding: 35px;
  border-radius: 5px;
  animation-name: wrapperAnimation;
  animation-duration: 0.5s;
}

.Modal-close-icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
  transition: all 1s;
}

.Modal-close-icon:hover {
  height: 15px;
  width: 15px;
  opacity: 0.8;
  transform: rotateZ(180deg);
  fill: #333333;
  stroke: #333333;
}

.Modal-close-icon-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 780px) {
  .Modal-wrapper.Modal-not-content-fix {
    width: 70vw;
    height: 25vh;
  }
}
