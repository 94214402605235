:root {
  /* --primaryColor: rgb(12, 26, 104);
  --primaryColorLight: rgb(32, 52, 170);
  --primaryColorSelected: rgb(6, 13, 53);
  --primaryColorHover: rgb(36, 25, 136);

  --secondaryColor: #011638;

  --headerBackgroundColor: rgb(227, 231, 235); */

  /* Button */
  /* --primaryButtonBackgroundColor: var(--primaryColorLight);
  --primaryButtonBorderColor: var(--primaryColorLight);

  --primaryButtonHoverBackgroundColor: var(--primaryColor);
  --primaryButtonHoverBorderColor: var(--primaryColor); */

  /* Input */
  /* --inputBorderFocus: var(--primaryColorLight); */

  --scrollWidth: 7px;
}

/*
4A4063
4F1271
783F8E
12100E
173753
*/
