html {
  -webkit-tap-highlight-color: transparent;
}

.Loading {
  border-top: 5px solid #222222;
  border-radius: 50%;
  position: absolute;
  top: 20%;
  height: 40px;
  width: 40px;
  animation-name: animationRot;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-left: 5px solid rgba(150, 150, 150, 0.7);
  border-right: 5px solid rgba(150, 150, 150, 0.7);
  border-bottom: 5px solid rgba(150, 150, 150, 0.7);
}

@keyframes animationRot {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
