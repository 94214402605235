.transition{
  transition: all 0.3s ease;
}

.transparent{
  opacity: 0;
}

.HomeReserva-header{
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 15px; */

  transform: translateY(-50px);
  transition: all 0.5s;
  z-index: 10;

}

.HomeReserva-text-container{
  display: "flex";
  justify-content: "end"; 
  flex-direction: "column";
}

@media (max-width: 800px) {
  .HomeReserva-section-change{
    flex-direction: column;
  }

  .HomeReserva-portada{
    align-self:flex-end;
  }

  .HomeReserva-subsectionRight{
    padding: 0 50px;
    text-align: center;
    justify-content: flex-start;
  }
}

.HomeReserva-header-visible{
  transform: translateY(0px);

}

.HomeReserva-logo-header{
  height: 35px;
  margin-left: 20px;
  cursor: pointer;
}

.HomeReserva-hamburguer-header{
  height: 30px;
  filter: invert(1);
  margin-right: 20px;
  cursor: pointer;
}

.HomeReserva-background-layout{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* background-image: url("./../../../assets/background_reserva.svg"); */
}

.HomeReserva-background{
  position: absolute;
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.HomeReserva-section{
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.HomeReserva-subsection{
  /* width: 100%;
  height: 100%; */
  flex-grow: 1;
  /* background-color: rgba(0, 0, 255, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.HomeReserva-subsectionRight{
  padding-left: 50px;
}

.HomeReserva-portada{
  width: min(500px, 80vw);
  height: min(500px, 80vw);
  /* pointer-events: all; */

  border-radius: 14px;
  transition: all 0.7s ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px;


  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  /* transform-style: preserve-3d; */
}

.HomeReserva-portada:hover{
  transform: scale(105%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.HomeReserva-text{
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

.HomeReserva-title{
  font-size: 2em;
  font-weight: normal;
  letter-spacing: 0.35em;
  line-height: 1.4em;
  
}

.HomeReserva-subtitle{
  font-size: 1em;
  letter-spacing: 0.35em;
  line-height: 1.4em;
}

.HomeReserva-subtitle-section{
  font-size: 1.5em;
  letter-spacing: 0.35em;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.HomeReserva-button{
  margin-top: 20px;
  width: fit-content;
  padding: 0.85em;
  font-size: 1em;
  color: black;
  border: none;
  transition: all 500ms;
  cursor: pointer;
  pointer-events: all;
  background-color: white;
  line-height: 5;
}

.HomeReserva-button:hover{
  color: white;
  background-color: black;
}

.HomeReserva-videosection{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}

.HomeReserva-disco-section{

  font-size: 50px;

}
/*
.HomeReserva-background2{
  position: absolute;

  background-color: red;
  width: 100%;
  height: 100%;

  transform: rotate();
}

.HomeReserva-background3{
  display: none;
  position: absolute;

  background-color: green;
  width: 100%;
  height: 100%;

} */