.Footer-container {
  z-index: 11;
  position: sticky;
  bottom: 0;
  height: 50px;
  width: calc(100%);
  background-color: #222222;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  clear: both;
}

.Footer-icon-container {
  margin: 10px;
  cursor: pointer;
}

.Footer-icon-container:hover {
  margin: 9px 9px;
}

.Footer-icon-container:hover > .Footer-icon {
  width: 27px;
  height: 27px;
}

.Footer-icon {
  width: 25px;
  height: 25px;
  stroke: white;
  fill: white;
}

.Footer-icon:hover > * {
  /* stroke: red;
  fill: red; */
}

.Footer-icon:hover {
  /* width: 30px;
  height: 30px;
  margin: 10px 7px; */
}

.facebook-icon:hover > * {
  stroke: #4267b2;
  fill: #4267b2;
}

.twitter-icon:hover > * {
  stroke: #1da1f2;
  fill: #1da1f2;
}

.instagram-icon:hover > * {
  stroke: #c32aa3;
  fill: #c32aa3;
}

.spotify-icon:hover > * {
  stroke: #1ed760;
  fill: #1ed760;
}

.souncloud-icon:hover > * {
  stroke: #ff5500;
  fill: #ff5500;
}

.youtube-icon:hover > * {
  stroke: #ff0000;
  fill: #ff0000;
}

@media (max-width: 1025px) {
  .Footer-container {
    z-index: 102;
  }
}

@media (max-width: 650px) {
  .Footer-container {
    justify-content: space-evenly;
  }
}
